import axios from 'axios';

export const checkNewMessages = (sessionId) =>
  axios.get(`/api/client/checknewmail?clientSessionId=${sessionId}`);

export const getAllMessages = (sessionId) =>
  axios.get(`/api/client/mail?clientSessionId=${sessionId}`);

export const getMessageById = (sessionId, mailId) =>
  axios.get(`/api/client/mail/${mailId}?clientSessionId=${sessionId}`);

export const getMailAttachment = (sessionId, mailId, attachmentName) =>
  axios.get(
    `/api/client/mail/${mailId}/attachment/${attachmentName}?clientSessionId=${sessionId}`
  );

export const deleteMail = (sessionId, mailId) =>
  axios.delete(`/api/client/mail/${mailId}?clientSessionId=${sessionId}`);
