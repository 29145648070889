export const companyNamesRu = {
  ingos: 'Ингосстрах',
  alpha: 'Альфа',
  renessans: 'Ренессанс',
  rgs: 'Росгосстрах',
  tinkoff: 'Тинькофф',
  soglasie: 'Согласие',
  vsk: 'ВСК',
  makc: 'МАКС',
  absolut: 'Абсолют',
  verna: 'Верна',
  intouch: 'Intouch'
};
