import { USER_LOGED_IN, USER_LOGED_OUT } from '../actions/types';

const initialState = {
  isAuthenticated: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_LOGED_IN:
      return {
        isAuthenticated: true,
        user: action.payload,
      };
    case USER_LOGED_OUT:
      return {
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
