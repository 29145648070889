import { apiCall } from '../helpers/apiHelpers';
import axios from 'axios';

const url = {
  login: '/api/client/login',
  logout: '/api/client/logout?clientSessionId=',
};

export const login = apiCall({
  method: 'POST',
  url: url.login,
});

export const logout = (sessionId) =>
  apiCall({
    method: 'GET',
    url: `${url.logout}${sessionId}`,
  })();

export const getUserInfoById = (sessionId) =>
  axios.get(`/api/client/session?clientSessionId=${sessionId}`);
