import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { checkNewMessages } from '../services/messagesServices';
import { checkNewMesssagesSuccess } from '../actions/messagesActions';

import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';

const Layout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const { sessionId } = useSelector((state) => state.auth.user);

  const checkMessages = () =>
    checkNewMessages(sessionId).then((res) =>
      dispatch(checkNewMesssagesSuccess(res.data.data))
    );

  useEffect(() => {
    checkMessages();

    const checkMailInterval = setInterval(() => checkMessages(), 60000);

    return () => clearInterval(checkMailInterval);
  }, []);

  const toggleSidebar = () => setSidebarOpen((exist) => !exist);

  return (
    <div className="layout layout--base">
      <Header
        className="layout-header shadow"
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
      />
      {/* <Sidebar className="layout-side" isOpen={isSidebarOpen} /> */}
      <main className="layout-main">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
