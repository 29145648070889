import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer shadow">
      <nav className="footer-nav">
        <ul className="footer-list">
          <li className="footer-item">
            <a href="https://osago.one/help" target={'_blank'} className="footer-link">
              Вопросы?
            </a>
          </li>
          <li className="footer-item">
            <a href="https://osago.one/rgs/prolong/" target={'_blank'} className="footer-link">
              Продлить Росгосстрах
            </a>
          </li>
          <li className="footer-item">
            <a href="https://osago.one/ingos/prolong/" target={'_blank'} className="footer-link">
              Продлить Ингосстрах
            </a>
          </li>
          <li className="footer-item">
            <a href="https://osago.one/alpha/prolong/" target={'_blank'} className="footer-link">
              Продлить Альфастрахование
            </a>
          </li>
          <li className="footer-item">
            <a href="https://dkbm-web.autoins.ru/dkbm-web-1.0/policyInfo.htm" target={'_blank'} className="footer-link">
              Поиск ОСАГО в РСА
            </a>
          </li>
          <li className="footer-item">
            <a href="https://osago.one/contracts" target={'_blank'} className="footer-link">
              Документы с СК
            </a>
          </li>
          <li className="footer-item">
            <a href="https://osago.one/privacy" target={'_blank'} className="footer-link">
              Политика конфиденциальности
            </a>
          </li>
        </ul>
      </nav>
      <div className="footer-contacts">
        (с) {year}, Blockchain LLC, OOO “Блокчейн”, 390006, Рязань, <br /> 
        пл. 26 Бакинских комиссаров, 6/21, пом. Н1, ИНН 6234181385, ОГРН 1186234018442 <br /> 
        Дирекция в г. Москве: Khimki Business Park, г. Химки, ул. Ленинградская, вл 39, стр. 6, тел: +74952752010 <br /> 
        © {new Date().getFullYear()}, Blockchain LLC,
      </div>
    </footer>
  );
};

export default Footer;
