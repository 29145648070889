import { CHECK_NEW_MESSAGSES } from '../actions/types';

const initialState = {
  hasNew: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CHECK_NEW_MESSAGSES:
      return {
        ...state,
        hasNew: action.payload,
      };

    default:
      return state;
  }
};
