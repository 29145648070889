import React, { useRef } from 'react';
import cn from 'classnames';

import Portal from '../Portal';

import { useOnClickOutside } from '../../hooks/useOnClickOutside';

import './Modal.css';

const Modal = ({ children, onClose, isOpen, className }) => {
  const windowRef = useRef(null);
  const classes = cn('modal', className);

  useOnClickOutside(windowRef, onClose);

  return (
    <>
      {isOpen && (
        <Portal>
          <div className={classes}>
            <div className="modal-window" ref={windowRef}>
              <button className="modal-close" onClick={onClose}>
                &times;
              </button>
              {children}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Modal;
