import React from 'react';

class Frame extends React.Component {
  componentDidMount() {
    this._updateIframe();
  }

  componentDidUpdate() {
    this._updateIframe();
  }

  _updateIframe() {
    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    const head = document.getElementsByTagName('head')[0];
    document.body.innerHTML = this.props.content;

    iframe.scrolling = 'no';
    iframe.style.width = '100%';
    iframe.style.height = document.documentElement.scrollHeight + 'px';
  }

  render() {
    return <iframe ref="iframe" onLoad={this._updateIframe.bind(this)} />;
  }
}

export default Frame;
