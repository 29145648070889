function isGoCounterExists() {
    return Boolean(window.gtag);
}

export const trackLK = (label) => {
    const google = isGoCounterExists();

    if (!google) return;

    window.gtag('event', 'track', {
        event_category: 'lk',
        event_label: label,
    });
};