import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

import { getAllMessages } from '../services/messagesServices';
import { trackLK } from '../metriks';

const MessagesPage = () => {
  const [mails, setMails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { sessionId } = useSelector((state) => state.auth.user);

  useEffect(() => {
    setIsLoading(true);

    getAllMessages(sessionId).then((res) => {
      setMails(res.data.data.mails);
      setIsLoading(false);
    });

    trackLK('messages')
  }, []);

  const renderMessagesList = () => {
    if (!isLoading && mails.length) {
      return mails.map((message, key) => (
        <li key={key}>
          <Link
            to={`/messages/${message.id}`}
            className="link cursor-pointer hover:text-royalblue"
          >
            {message.subject}
          </Link>
        </li>
      ));
    }

    if (!isLoading && !mails.length) {
      return <p className="text-center">Сообщений нет.</p>;
    }

    return <p className="text-center">Загрузка...</p>;
  };

  return (
    <Layout>
      <Helmet>Мои сообщения | osago.one</Helmet>
      <div className="box box--bordered">
        <ul className="list-messages">{renderMessagesList()}</ul>
      </div>
    </Layout>
  );
};

export default MessagesPage;
