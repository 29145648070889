import React from 'react';

import sprite from '../img/sprite.svg';

const Icon = ({ name, width, height, className }) => (
  <svg className={className} width={width} height={height}>
    <use xlinkHref={`${sprite}#${name}`} />
  </svg>
);

export default Icon;
