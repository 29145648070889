import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import qs from 'query-string';
import cogoToast from 'cogo-toast';

import store from './store';
import { userLogedIn, loginAction } from './actions/authActions';
import { getUserInfoById } from './services/authService';

import App from './app';
import history from './components/history';

const parsed = qs.parse(window.location.search);

if (sessionStorage.authInfo) {
  const info = JSON.parse(sessionStorage.authInfo);

  store.dispatch(userLogedIn(info));
}

if (parsed.sessionId) {
  getUserInfoById(parsed.sessionId).then((res) => {
    if (res.data.error) {
      history.push('/');
      cogoToast.error(res.data.message, { position: 'top-right' });
      return;
    }

    store.dispatch(loginAction(res.data.data));
    history.push('/');
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('reactor')
);
