import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { compose, fromPairs, map, split, tail } from 'ramda';

import LoginForm from '../components/loginForm';
import Modal from '../components/Modal';

const parseQs = compose(fromPairs, map(split('=')), split('&'), tail);

const LoginPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const queryObj = parseQs(window.location.search);

    if (queryObj.success === 'true') {
      openModal();
    }
  }, []);

  return (
    <div className="login flex flex-col items-center justify-center flex-grow p-4">
      <Helmet>
        <title>Вход в личный кабинет | osago.one</title>
      </Helmet>
      <LoginForm />

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="mb-6 text-xl font-bold text-center">
          Поздравляем с покупкой полиса!
        </h2>
        <p className="mb-4">
          Ваш полис успешно оплачен и будет доставлен на вашу электронную
          почту в течение 40 минут.
        </p>
        <p>
          Также полис будет доступен в личном кабинете, данные для авторизации
          мы отправили вам отдельным письмом. Если у вас возникли какие-либо
          вопросы или сложности, пожалуйста, напишите нашим специалистам в
          онлайн-чат, который доступен на главной странице нашего сервиса{' '}
          <a
            className="text-royalblue hover:underline"
            href="http://osago.one"
            target="_blank"
          >
            osago.one
          </a>
        </p>
      </Modal>
    </div>
  );
};

export default LoginPage;
