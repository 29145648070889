import { USER_LOGED_IN, USER_LOGED_OUT } from './types';

export function userLogedIn(data) {
  return {
    type: USER_LOGED_IN,
    payload: data,
  };
}

export function userLogedOut() {
  return {
    type: USER_LOGED_OUT,
  };
}

export function loginAction(userInfo) {
  return (dispatch) => {
    sessionStorage.setItem('authInfo', JSON.stringify(userInfo));
    dispatch(userLogedIn(userInfo));
  };
}

export function logoutAction() {
  return (dispatch) => {
    sessionStorage.removeItem('authInfo');
    dispatch(userLogedOut());
  };
}
