import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import DashboardPage from './pages/dashboardPage';
import MessagesPage from './pages/messagesPage';
import SingleMessagesPage from './pages/singleMessagePage';
import LoginPage from './pages/loginPage';
import PassResetPage from './pages/passResetPage';
import NotFoundPage from './pages/notFoundPage';

import PrivateRoute from './components/privateRoute';
import history from './components/history';

import './styles/main.css';
import { trackLK } from './metriks';

const App = () => {
  useEffect(() => {
    trackLK('visit')
  }, [])

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path="/" component={DashboardPage} />
        <PrivateRoute exact path="/messages" component={MessagesPage} />
        <PrivateRoute path="/messages/:id" component={SingleMessagesPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/passreset" component={PassResetPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  )
};

export default App;
