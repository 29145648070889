import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <div className="flex flex-col flex-grow items-center justify-center p-4">
    <p className="text-royalblue text-5xl font-bold">404</p>
    <p className="text-xl font-medium mb-6">Страница не найдена</p>
    <Link to="/" className="font-bold text-royalblue text-lg hover:underline">
      Назад
    </Link>
  </div>
);

export default NotFoundPage;
