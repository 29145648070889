import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import download from 'downloadjs';

import Layout from '../components/layout';
import Icon from '../components/icon';
import Frame from '../components/frame';

import {
  getMessageById,
  getMailAttachment,
  deleteMail,
} from '../services/messagesServices';

const SingleMessagePage = ({ match: { params } }) => {
  const [message, setMessage] = useState(null);
  const { sessionId } = useSelector((state) => state.auth.user);
  const history = useHistory();
  const { id } = params;

  useEffect(() => {
    getMessageById(sessionId, id).then((res) => setMessage(res.data.data));
  }, []);

  const handleAttachmentClick = (name) =>
    getMailAttachment(sessionId, id, name).then((res) =>
      download(
        `data:application/pdf;base64,${res.data.data.content}`,
        res.data.data.name
      )
    );

  const handleDeleteClick = () => {
    deleteMail(sessionId, id);
    history.push('/messages');
  };

  return (
    <Layout>
      <Helmet defaultTitle="Загрузка...">
        <title>{message && `Сообщение: ${message.subject} | osago.one`}</title>
      </Helmet>
      <div className="box box--bordered">
        {message ? (
          <div className="message">
            <div className="message-actions">
              <Link to="/messages" className="message-action">
                <Icon
                  name="angle-left"
                  className="fill-current mr-2"
                  width={14}
                  height={14}
                />
                Назад
              </Link>
              <button className="message-action" onClick={handleDeleteClick}>
                Удалить
                <Icon
                  name="trash"
                  className="fill-current ml-2"
                  width={16}
                  height={16}
                />
              </button>
            </div>
            <h2 className="message-title">{message.subject}</h2>

            <div className="message-body">
              <Frame content={message.body} />
            </div>

            {message.attachments.length > 0 && (
              <p className="font-semibold leading-tight mb-2">Вложения:</p>
            )}
            <ul className="messages-attachments">
              {message.attachments.map((item, key) => {
                const filename = /[.]/.exec(item)
                  ? /[^.]+$/.exec(item)
                  : undefined;

                if (!filename) return;

                return (
                  <li
                    key={key}
                    onClick={handleAttachmentClick.bind(null, item)}
                  >
                    {item}
                    <Icon
                      name="paperclip"
                      className="fill-current ml-2"
                      width={14}
                      height={14}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <p className="text-center">Загрузка...</p>
        )}
      </div>
    </Layout>
  );
};

export default SingleMessagePage;
